:root {
  --deskWidth: 1920px;
  --deskSpace: 0;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
:root {
  --spacePart: 12px;
}
@media (max-width: 1279px) {
  :root {
    --spacePart: 12px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 12px;
  }
}
:root {
  --spaceUnit: 12px;
}
@media (max-width: 1279px) {
  :root {
    --spaceUnit: 12px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 12px;
  }
}
:root {
  --spaceTotal: 24px;
}
@media (max-width: 1279px) {
  :root {
    --spaceTotal: 24px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 24px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #000000;
  line-height: 1.2;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #000000;
}
.button:active {
  background-color: #000000;
}
.area--one {
  width: 100%;
}
.area--one > .unit {
  margin-right: 0.9375%;
  margin-left: 0.9375%;
  width: 98.125%;
}
.area--one .cb-album .body,
.area--one .head,
.area--one .foot,
.area--one .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--one > .slim {
  width: 48.125%;
}
.area--one > .slim .head,
.area--one > .slim .foot,
.area--one > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--one > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--one > .slim .tiny {
  width: 100%;
}
.area--one {
  width: 101.91082803%;
  margin-left: -0.95541401%;
}
.area--one .unit.wide .part.pict * {
  height: 100%;
}
.area--one .unit.wide .part.pict img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: calc(100vh - 160px) !important;
  /* Use vh as a fallback for browsers that do not support Custom Properties */
  max-height: calc((var(--vh, 1vh) * 100) - 160px) !important;
  object-fit: contain;
  object-position: top left;
}
/*# sourceMappingURL=./screen-medium.css.map */